<template>
  <div class="switcher">
    <input type="checkbox" v-model="computedValue" id="fastener-switcher" />
    <label for="fastener-switcher" class="switcher__label"></label>
  </div>
</template>

<script>
export default {
  props: {
    calculationNeed: {
      type: Boolean,
      required: true
    }
  },
  name: 'Switcher',
  computed: {
    computedValue: {
      get() {
        return this.calculationNeed
      },
      set(val) {
        this.$emit('updateSwitchingValue', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.switcher
  min-height: rem(24)
  margin-right: rem(6)
  display: flex
  align-items: center
  input
    display: none
    pointer-events: none
    opacity: 0
    position: absolute
    &:checked
      + .switcher__label
          &:after
            transform: translate(100%, -50%)
            left: unset
            background: $main
  &__label
    height: rem(14)
    width: rem(42)
    background: $ec
    border-radius: rem(12)
    position: relative
    display: block
    cursor: pointer
    &:after
      content: ""
      background: $white
      height: rem(24)
      width: rem(24)
      display: block
      border-radius: 50%
      position: absolute
      top: 50%
      left: 0
      right: unset
      transition: .5s
      transform: translate(-6px, -50%)
      box-shadow: 0 rem(3) rem(6) rgba(0, 0, 0, 0.16)
</style>
