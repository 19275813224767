import { render, staticRenderFns } from "./Switcher.vue?vue&type=template&id=fe631cce&scoped=true"
import script from "./Switcher.vue?vue&type=script&lang=js"
export * from "./Switcher.vue?vue&type=script&lang=js"
import style0 from "./Switcher.vue?vue&type=style&index=0&id=fe631cce&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe631cce",
  null
  
)

export default component.exports