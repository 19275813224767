<template>
  <button :class="classes" @click="handler">
    <slot name="icon"></slot>
    <span class="simple-btn__text">
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'red',
      required: true,
      validator: v => ['red', 'grey'].includes(v)
    },
    noRadius: {
      type: Boolean
    }
  },
  name: 'simpleBtn',
  methods: {
    handler() {
      this.$emit('btnClicked')
    }
  },
  computed: {
    classes() {
      return {
        'simple-btn': true,
        [`simple-btn--${this.color}`]: this.color,
        [`simple-btn--no-radius`]: this.noRadius
      }
    }
  }
}
</script>

<style scoped lang="sass">
.simple-btn
  @extend %clear-btn
  font-style: normal
  font-weight: bold
  font-size: rem(14)
  line-height: 100%
  display: inline-flex
  align-items: center
  min-height: rem(50)
  transition: .5s
  border-radius: rem(4)
  +media((padding: (320: rem(14) rem(18), 768: rem(14) rem(26))))
  .icon
    +media((margin-right: (320: rem(15), 768: rem(20))))
  &--red
    color: $white
    background: $main
    @media(any-hover: hover)
      &:hover
        background: $main
        opacity: $hover-opacity
  &--grey
    color: $light-black
    background:  $semi-white
    @media(any-hover: hover)
      &:hover
        background: $white
  &--no-radius
    border-radius: 0
</style>
