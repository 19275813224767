export const getRowNumberFontSize = plateWidth => {
  const size = Math.round(plateWidth / 2)
  return size > 12 ? 12 : size
}
export const getRowNumberX = (changeWidthRight, plateWidth, i) => {
  return changeWidthRight - plateWidth * i + plateWidth / 2
}

export const getRowNumberXForTriangles = (
  startingPoint,
  plateWidth,
  i,
  width,
  scale,
  ratio,
  isLeftDirection
) => {
  const horizontalCathet = width * scale
  const verticalCathet = (width / ratio) * scale
  const hypotenuse = Math.sqrt(horizontalCathet ** 2 + verticalCathet ** 2)
  const vectorHeight = (horizontalCathet * verticalCathet) / hypotenuse
  return isLeftDirection
    ? startingPoint - vectorHeight + plateWidth * i - plateWidth / 2
    : startingPoint + vectorHeight - plateWidth * i + plateWidth / 2
}

export const getValleyY = (schemeHeight, figureParams, figureBottomY) => {
  return !figureParams.offsetValley ? figureBottomY : schemeHeight - 10
}

export const defaultSvgParams = {
  width: 720, // 680 // 720
  height: 520, // 480 // 520
  baseHeight: 4,
  baseWidth: 6,
  baseBiasW: 2,
  ratio: 3,
  navScale: 0.1765
}

export const colors = {
  green: '#68CA66',
  violet: '#BEA8FF',
  orange: '#FF6928',
  blue: '#1FAFED',
  yellow: '#F4BE34',
  grey: '#999999',
  nav: '#C8C8C8'
}

export const drawSizeArrowVertical = (x, y1, y2) => {
  return `M${x},${y1}
          V${y2}
          M${x - 5},${y2 - 6}
          L${x},${y2}
          M${x + 5},${y2 - 6}
          L${x},${y2}
          M${x - 5},${y1 + 6}
          L${x},${y1}
          M${x + 5},${y1 + 6}
          L${x},${y1}
          `
}

export const drawSizeArrowHorizontal = (x1, y, x2) => {
  return `M${x1},${y}
          H${x2}
          M${x1 + 6},${y - 5}
          L${x1},${y}
          M${x1 + 6},${y + 5}
          L${x1},${y}
          M${x2 - 6},${y - 5}
          L${x2},${y}
          M${x2 - 6},${y + 5}
          L${x2},${y}
          `
}

export const drawArrowDirection = (x1, x2, y, size, isLeft) => {
  const rightPath = `M${x1},${y}
                     H${x2}
                     M${x2 - size}, ${y - size}
                     L${x2},${y}
                     M${x2 - size}, ${y + size}
                     L${x2},${y}`
  const leftPath = `M${x1},${y}
                     H${x2}
                     M${x2 + size}, ${y - size}
                     L${x2},${y}
                     M${x2 + size}, ${y + size}
                     L${x2},${y}`
  return isLeft ? leftPath : rightPath
}

export const getCathetSize = (width, ratio) => {
  return parseFloat((width / ratio).toFixed(2))
}

export const formsComponents = {
  triangle: () => import('@/components/figures/Triangle'),
  doubleSideTriangleHorizontal: () => import('@/components/figures/DoubleSideTriangleHorizontal'),
  doubleSideTriangleVertical: () => import('@/components/figures/DoubleSideTriangleVertical'),
  rhombus: () => import('@/components/figures/Rhombus'),
  rectangularTrapezoidVertical: () => import('@/components/figures/RectangularTrapezoidVertical'),
  rectangularTrapezoidHorizontal: () =>
    import('@/components/figures/RectangularTrapezoidHorizontal'),
  doubleSideRectangularTrapezoidVertical: () =>
    import('@/components/figures/DoubleSideRectangularTrapezoidVertical'),
  square: () => import('@/components/figures/Square'),
  trapezoid: () => import('@/components/figures/Trapezoid'),
  reverseTrapezoid: () => import('@/components/figures/ReverseTrapezoid'),
  cornerLeftTop: () => import('@/components/figures/CornerLeftTop'),
  cornerRightTop: () => import('@/components/figures/CornerRightTop'),
  cornerLeftBottom: () => import('@/components/figures/CornerLeftBottom'),
  cornerRightBottom: () => import('@/components/figures/CornerRightBottom')
}

export const matrixZoom = (zoom, svgParams, figureWrapper) => {
  const { width, height } = svgParams
  const figureWidth = figureWrapper.offsetWidth - 48
  const figureHeight = figureWrapper.offsetHeight
  const isMobile = window.screen.width <= 767
  const computedWidth = isMobile ? figureWidth : width
  const computedHeight = isMobile ? figureHeight : height
  const translateX = (computedWidth * zoom) / 2 - computedWidth / 2
  const translateY = (computedHeight * zoom) / 2 - computedHeight / 2
  return `matrix(${zoom},0,0,${zoom},-${translateX},-${translateY})`
}

const permittedBasements = ['custom']

export const checkIsOffsetValleyNeed = (slope, slopeParams) => {
  const isOffsetValleyExist = Object.prototype.hasOwnProperty.call(slopeParams, 'offsetValley')
  const roofOptionBase = slope.roofOptions?.base
  return isOffsetValleyExist && permittedBasements.includes(roofOptionBase)
}
