<script>
import { mapActions } from 'vuex'

export default {
  name: 'UpdateStepMixin',
  methods: {
    ...mapActions({
      sendMetrics: 'sendMetrics'
    }),
    sendMetricsMethod(stepNumber) {
      const sessionToken = sessionStorage.getItem('sessionToken')
      this.sendMetrics({
        stepNumber,
        sessionToken
      })
    },
    updateStep(stepNumber) {
      this.sendMetricsMethod(stepNumber)
    }
  }
}
</script>

<style scoped></style>
